<template>
    <div class="loading-programs" >
        <div class="exhibition_bgi" v-if="videoLoad">
            <div class="exhibition">
                <div class="xing3"></div>
                <div class="blue-out">
                    <div class="blue_qiu"></div>
                </div>

                <div class="yellow_qiu"></div>
                <div class="xing2"></div>
                <div class="xing1"></div>
                <div class="logo_out">
                    <div class="logo animate__animated animate__bounceInDown "> </div>
                </div>
                <div class="renwu_out">
                    <div class="renwu"></div>
                </div>
                <div class="loading_out">
                   提示:元宇宙数据加载中 <span v-text="percentage"></span> %
                </div>
                <div class="tips animate__animated animate__pulse animate__infinite">人人可用的元宇宙</div>
                <div class="button-out">
                    <div v-show="enter"  @click="enterExhibition" class="c-button animate__animated animate__fadeInUp"></div>
                </div>
            </div>
        </div>
        <div class="choose-type" v-if="!videoLoad">
            <div>
         
                <div>
                    <div class="btn-shine" @click="chooseVisitType(1)">
                        <img src="zdmy.png">
                    </div>
                    <div class="btn-shine" @click="chooseVisitType(2)">
                        <img src="sdmy.png">
                    </div>
                </div>
                
            </div>
        </div>
    </div>
</template>

<script>

import {getQueryVariable,getUserAgentInfo} from "@/js/tool";
export default {
    name: 'loadingProgramNew',
    props:{
        tem_id:Number,
        percentage:Number
    },
    data(){
        return{
            enter:false,
            videoLoad:true
        }
    },
    created(){
        document.documentElement.style.fontSize = document.documentElement.clientWidth / 750 * 100 + 'px';
    },
    methods:{

        readyEnter(){
            this.enter = true;  
        },
        enterExhibition(){
            let versions = getUserAgentInfo()
            if(versions.mobile && versions.android){
                switchFullScreen();
            }
            this.videoLoad = false;
            if(this.tem_id != 44){
                this.$emit('changeLoadingStatus');
            }
            if(this.tem_id == 80){
                playAudio('音频/new-year.mp3');
            }
        },
        chooseVisitType(type){
            this.$emit('changeLoadingStatus');

            if(type == 1){
                window.game.startRoaming();
                this.$emit('initNav')
            }
            if(type == 2){
                window.game.cameraControl.stopRoaming();
            }
        }
    }
}
</script>
<!-- 两种样式 768px为边界线-->
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
*::-webkit-media-controls-start-playback-button {
  display: none ;
  -webkit-appearance: none;
}
*::-webkit-media-controls-panel {
  display: none ;
  -webkit-appearance: none;
}

*::--webkit-media-controls-play-button {
  display: none ;
  -webkit-appearance: none;
}

::-webkit-scrollbar {
  display: none;
}
.loading-programs{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0,0,0,0.4);
}
.loading-programs > .choose-type{
    width: 100%;
    height: 100%;
    /* background-image: linear-gradient(120deg,#fccb90 0%,#d57eeb 100%); */
    display: flex;
    justify-content: center;
    align-items: center;
}
.loading-programs > .choose-type > div{
    width: 350px;
    height: 300px;
    opacity:1;
}
* {
    background-repeat: no-repeat;
    background-size: 100% 100%;
    background-position: center;
}

.exhibition_bgi {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}
.exhibition {
    width: 100%;
    height: 100%;
    position: relative;
}

/* 电脑端样式 */
@media screen and (min-width:768px) {
    .exhibition_bgi {
        background-image: url('/bgi/pc/bgi.png');
    }
    .loading-programs > .choose-type > div > div{
        width: 100%;
        font-size: 22px;
        display: flex;
        margin-top: 20px;
        justify-content: space-between;
    }
    .loading-programs > .choose-type > div > div > div{
        width: 150px;
        height: 50px;
        line-height: 50px;
        text-align: center;
        cursor: pointer;
    }
    .loading-programs > .choose-type > div > div > div > img{
        width: 150px;
        height: 150px;
    }
    .xing1 {
        background-image: url('/bgi/pc/xing1.png');
        width: 62px;
        height: 64.9px;
        position: absolute;
        left: 30.4%;
        top: 59.5%;
        animation: sacal 2s infinite linear;
    }

    .xing2 {
        background-image: url('/bgi/pc/XING2.png');
        width: 40.5px;
        height: 72.1px;
        position: absolute;
        left: 90%;
        top: 27.68%;
        animation: sacal 2s infinite linear;
    }

    @keyframes sacal {
        0% {
            transform: scale(.1);
        }

        50% {
            transform: scale(1);
        }

        100% {
            transform: scale(.1);
        }
    }

    .blue_qiu {
        width: 441.2px;
        height: 441.2px;
        position: absolute;
        top: 41.82%;
        left:0 ;
        background-image: url('/bgi/mobile/blueQiu.png');
        animation: roate 3s infinite linear;
    }
    .yellow_qiu{
        width: 167.3px;
        height: 167.3px;
        position: absolute;
        top: 3.42%;
        left:22.5%;
        background-image: url('/bgi/mobile/yellowQiu.png');
        animation: roate 3s infinite linear;
    }
    @keyframes roate {
        0% {
            transform: rotate(0);
        }

        50% {
            transform: rotate(180deg);
        }

        100% {
            transform: rotate(360deg);
        }
    }
    .renwu_out{
        width:100%;
        height: 100%;
        position: absolute;
        z-index: 998;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .renwu {
        width:745.9px;
        height: 639.1px;
        position: absolute;
        background-image: url('/bgi/pc/renwu.png');
        animation: renwu 4s infinite linear;
    }

    @keyframes renwu {
        0% {
            transform: rotate(0deg);
            transform-origin: bottom;
        }
        10% {
            transform: rotate(2deg);
            transform-origin: bottom;
        }
        25% {
            transform: rotate(5deg);
        }
        50% {
            transform: rotate(-2deg);
        }
        75% {
            transform: rotate(-4deg);
        }
        100% {
            transform: rotate(0deg);
        }
    }
    .logo_out{
        width: 471px;
        height: 127px;
        position: absolute;
        top: 34.44%;
        right: 5%;
        display: flex;
        justify-content: center;
        align-items: center;
        z-index:999;
    }
    .logo {
        width:90px;
        height: 90px;
        background-image: url('https://qn.ky3d.com/super_card/k-logo.png');
    }
    .tips {
        position: absolute;
        top: 51.75%;
        right: 5%;
        font-size: 60px;
        color: #FFFFFF;
        z-index: 999;
    }
    .loading_out {
        position: absolute;
        top: 61.75%;
        right: 6%;
        font-size: 32px;
        color: #FFFFFF;
        z-index: 999;
    }
    .button-out{
        position: absolute;
        right: 5%;
        top: 74.9%;
        z-index: 1000;
    }
    .c-button {
        width:432.6px;
        height: 132.7px;
        background-image: url('/bgi/mobile-landscape/button.png');
    }
}
/* 手机端竖屏样式 */
@media screen and (max-width:768px) and (orientation: portrait) {
    body {
        font-size: 0.16rem;
    }
    .loading-programs > .choose-type > div > div{
        width: 100%;
        font-size: 22px;
        display: flex;
        margin-top: 20px;
        justify-content: space-around;
    }
    .loading-programs > .choose-type > div > div > div{
        width: 150px;
        height: 50px;
        line-height: 50px;
        text-align: center;
        cursor: pointer;
    }
    .loading-programs > .choose-type > div > div > div > img{
        width: 100px;
        height: 100px;
    }
    .exhibition_bgi {
        background-image: url('/bgi/mobile/bgi.png');

    }

    .xing1 {
        background-image: url('/bgi/mobile/xing3.png');
        width: .48rem;
        height: .50rem;
        position: absolute;
        top: 1.53%;
        left: 54.53%;
        animation: sacal 2s infinite linear;
    }
    .xing2 {
        background-image: url('/bgi/mobile/xing2.png');
        width: .5rem;
        height: .66rem;
        position: absolute;
        top: 73.83%;
        left: 93.06%;
        animation: sacal 2s infinite linear;
    }

    .xing3 {
        background-image: url('/bgi/mobile/xing1.png');
        width: .73rem;
        height: .83rem;
        position: absolute;
        left: 69.33%;
        top: 60.52%;
        animation: sacal 2s infinite linear;
    }

    @keyframes sacal {
        0% {
            transform: scale(.1);
        }

        50% {
            transform: scale(1);
        }

        100% {
            transform: scale(.1);
        }
    }

    .blue_qiu {
        width: 2.58rem;
        height: 2.58rem;
        background-image: url('/bgi/mobile/blueQiu.png');
        position: absolute;
        left: 56%;
        top: 11.8%;
        animation: roate 3s infinite linear;
    }


    .yellow_qiu {
        width: .96rem;
        height: .96rem;
        background-image: url('/bgi/mobile/yellowQiu.png');
        position: absolute;
        left: 11.9%;
        top: 26.6%;
        animation: roate 3s infinite linear;
    }

    @keyframes roate {
        0% {
            transform: rotate(0);
        }

        50% {
            transform: rotate(180deg);
        }

        100% {
            transform: rotate(360deg);
        }
    }
    .logo_out{
        width: 100%;
        height: .87rem;
        position: absolute;
        top: 63.55%;
        display: flex;
        justify-content: center;
        z-index: 1000;
    }
    .logo {
        width: .9rem;
        height: .9rem;
        background-image: url('https://qn.ky3d.com/super_card/k-logo.png');
    }
    .renwu_out {
        width: 100%;
        height: 6.04rem;
        position: absolute;
        top: 26.72%;
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 999;
    }

    .renwu {
        width: 7.04rem;
        height: 6.04rem;
        background-image: url('/bgi/mobile/renwu.png');
        animation: renwu 4s infinite linear;
    }

    @keyframes renwu {
        0% {
            transform: rotate(0deg);
            transform-origin: bottom;
        }

        10% {
            transform: rotate(1deg);
            transform-origin: bottom;
        }

        25% {
            transform: rotate(2deg);
        }

        50% {
            transform: rotate(-1deg);
        }

        75% {
            transform: rotate(-2deg);
        }

        100% {
            transform: rotate(0deg);

        }
    }
    .button-out{
        position: absolute;
        left: 22%;
        top: 79.2%;
        z-index:1000;
        display: flex;
        width: 4.2rem;
        height: 1.2rem;
        justify-content: center;
        align-items: center;
    }
    .c-button {
        width: 3.6rem;
        height: 1.16rem;
        background-image: url('/bgi/mobile-landscape/button.png');
    }

    .tips {
        position: absolute;
        top: 70%;
        left: 22.4%;
        font-size: .5rem;
        color: #FFFFFF;
        z-index: 999;
    }
    .loading_out{
        position: absolute;
        top: 75%;
        left: 24.4%;
        font-size: .32rem;
        color: #FFFFFF;
        z-index: 999; 
    }
}
/* 手机端横屏样式 */
@media screen and (orientation: landscape) and  (max-width:900px){
body {
        font-size: 0.16rem;
    }
    .loading-programs > .choose-type > div > div{
        width: 100%;
        font-size: 22px;
        display: flex;
        margin-top: 20px;
        justify-content: space-around;
    }
    .loading-programs > .choose-type > div > div > div{
        width: 150px;
        height: 50px;
        line-height: 50px;
        text-align: center;
        cursor: pointer;
    }
    .loading-programs > .choose-type > div > div > div > img{
        width: 100px;
        height: 100px;
    }
    .exhibition_bgi {

        background-image: url('/bgi/mobile-landscape/bg.png');

    }

    .xing1 {
        background-image: url('/bgi/mobile/xing3.png');
        width: .48rem;
        height: .50rem;
        position: absolute;
        top: 1.53%;
        left: 54.53%;
        animation: sacal 2s infinite linear;
    }
    .xing2 {
        background-image: url('/bgi/mobile/xing2.png');
        width: .5rem;
        height: .66rem;
        position: absolute;
        top: 20.83%;
        left: 9.06%;
        animation: sacal 2s infinite linear;
    }

    .xing3 {
        background-image: url('/bgi/mobile/xing1.png');
        width: .73rem;
        height: .83rem;
        position: absolute;
        left: 69.33%;
        top: 27.52%;
        animation: sacal 2s infinite linear;
    }

    @keyframes sacal {
        0% {
            transform: scale(.1);
        }

        50% {
            transform: scale(0.5);
        }

        100% {
            transform: scale(.1);
        }
    }
    .blue-out{
        width: 1.4rem;
        position: absolute;
        left: 0.5rem;
        height: 100%;
    }
    .blue_qiu {
        width: 1.4rem;
        height: 1.4rem;
        background-image: url('/bgi/mobile/blueQiu.png');
        position: absolute;

        bottom:0.5rem;
        animation: roate 3s infinite linear;
    }


    .yellow_qiu {
        width: .6rem;
        height: .6rem;
        background-image: url('/bgi/mobile/yellowQiu.png');
        position: absolute;
        left: 2rem;
        top: 0.5rem;
        animation: roate 3s infinite linear;
    }

    @keyframes roate {
        0% {
            transform: rotate(0);
        }

        50% {
            transform: rotate(180deg);
        }

        100% {
            transform: rotate(360deg);
        }
    }
    .logo_out{
        position: absolute;
        top: 40%;
        right: 0.15rem;
        width: 2.1rem;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 0.44rem;
        z-index:999;
    }
    .logo {
        width: 0.48rem;
        height: 0.48rem;
        background-image: url('https://qn.ky3d.com/super_card/k-logo.png');
    }
    .tips {
        position: absolute;
        top: 57.7%;
        right: 0.15rem;
        font-size: .22rem;
        width: 2.1rem;
        color: #FFFFFF;
        z-index: 999;
            text-align: center;
    }
    .loading_out {
        position: absolute;
        top: 67.7%;
        right: 0.15rem;
        font-size: .12rem;
        width: 2.1rem;
        color: #FFFFFF;
        z-index: 999;
        text-align: center;
    }
    .renwu_out {
        width: 100%;
        height: 100%;
        position: absolute;
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 998;
    }
    .renwu {
        width: 3.06rem;
        height: 2.55rem;
        background-image: url('/bgi/mobile/renwu.png');
        animation: renwu 4s infinite linear;
    }

    @keyframes renwu {
        0% {
            transform: rotate(0deg);
            transform-origin: bottom;
        }

        10% {
            transform: rotate(1deg);
            transform-origin: bottom;
        }

        25% {
            transform: rotate(2deg);
        }

        50% {
            transform: rotate(-1deg);
        }

        75% {
            transform: rotate(-2deg);
        }

        100% {
            transform: rotate(0deg);

        }
    }

    .button-out{
        position: absolute;
        top: 78.7%;
        z-index:1000;
        right: 0.15rem;
        width: 2.1rem;
        height: 0.5rem;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .c-button{
        width: 1.56rem;
        height: .5rem;
        background-image: url('/bgi/mobile-landscape/button.png');
    }

}
</style>
